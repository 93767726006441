import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import AudioFile from '../../components/Layout/audio/nvda.wav';
import msc from './../../components/Layout/images/logo.svg';
import beta from './../../components/Layout/images/beta.png';
import play from './../../components/Layout/images/play.svg';
import application from './../../components/Layout/images/application.svg';
import line from './../../components/Layout/images/line.svg';
import building from './../../components/Layout/images/building.svg';
import pen from './../../components/Layout/images/pen.svg';
import people from './../../components/Layout/images/people.svg';
import document from './../../components/Layout/images/document.svg';
import NorkLogo from './../../components/Layout/images/NorkLogo.png';
// import hands from './../../components/Layout/images/hands.svg';
// import disability from './../../components/Layout/images/disability.svg';
// import ear from './../../components/Layout/images/ear.svg';
// import communication from './../../components/Layout/images/communication.svg';
import eyes from './../../components/Layout/images/eyes.svg';
// import head from './../../components/Layout/images/head.svg';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../helpers/constants';
import ModalVideo from 'react-modal-video';
import '../../components/Video/modal-video.scss';
import Video from '../../components/Layout/video/e-disability-sub.mp4';
import { Tooltip } from 'antd';

const HomePage = () => {
  const [isOpen, setOpen] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const audioRef = useRef(null);
  const year = new Date().getFullYear();

  const navigate = useNavigate();

  useEffect(() => {
    if (showAudio && audioRef.current) {
      audioRef.current.focus();
    }
  }, [showAudio]);

  const handlePlayAudio = () => {
    setShowAudio(true);
    audioRef.current.play();
  };


  return (
    <>
      <div className="main" content="width=device-width, initial-scale=1.0">
        <div className="header">
          <img src={msc} alt="" />
          <p>ԱՇԽԱՏԱՆՔԻ ԵՎ ՍՈՑԻԱԼԱԿԱՆ ՀԱՐՑԵՐԻ ՆԱԽԱՐԱՐՈՒԹՅԱՆ ՄԻԱՍՆԱԿԱՆ ՍՈՑԻԱԼԱԿԱՆ ԾԱՌԱՅՈՒԹՅՈՒՆ</p>
        </div>
        <div className="header_right">
          <img src={beta} alt="Այս ծառայությունը մշակման փուլում է" />
        </div>
        <div className="home-menu">
          {/*Responsive circle div*/}
          <div className="response-circle">
            <span className="play" onClick={() => setOpen(true)}>
              <img src={play} alt={'Դիտել տեղեկատու հոլովակը'} />
            </span>
            <span className="title">e-Disability</span>
            <span style={{ padding: '0 2px' }}>Անձի ֆունկցիոնալության գնահատման համակարգ</span>
          </div>
          {/*End*/}

          <div className="all-menu">
            <button className="menubar left" onClick={() => navigate(`/${PATHS.USERAPPLICATION}`)}>
              <div className="animation-line change-color"></div>
              <div className="icon">
                <img src={application} alt="" />
              </div>
              <div className="text">
                <p>Դիմում</p>
                <span className="hey">Ներկայացնել դիմում</span>
              </div>
            </button>
            <button className="menubar sec" onClick={() => navigate(`/${PATHS.HOMEPAGE}`)}>
              <div className="animation-line-sec change-color"></div>
              <div className="icon">
                <img src={building} alt="" />
              </div>
              <div className="text">
                <p>Տվյալների բազաների համադրում</p>
                <span>Ձեր անձնական տվյալները համադրվում են շտեմարաններում առկա տվյալների հետ</span>
              </div>
            </button>
            <button className="menubar third" onClick={() => navigate(`/${PATHS.HOMEPAGE}`)}>
              <div className="animation-line-third change-color"></div>
              <div className="icon">
                <img src={pen} alt="" />
              </div>
              <div className="text">
                <p>Ամփոփագրի մշակում</p>
                <span>Մասնագետի կողմից կազմվում է ամփոփագիրը</span>
              </div>
            </button>
            <button className="menubar sec" onClick={() => navigate(`/${PATHS.HOMEPAGE}`)}>
              <div className="animation-line-fourth change-color"></div>
              <div className="icon">
                <img src={people} alt="" />
              </div>
              <div className="text">
                <p>Հանձնաժողով</p>
                <span>Ինքնաշխատ կձեւավորվի հանձնաժողով, որը կգնահատի Ձեր ֆունկցիոնալության սահմանափակման աստիճանը</span>
              </div>
            </button>
            <button className="menubar left" onClick={() => navigate(`/${PATHS.HOMEPAGE}`)}>
              <div className="animation-line-fifth change-color"></div>
              <div className="icon">
                <img src={document} alt="" />
              </div>
              <div className="text">
                <p>Որոշում</p>
                <span>
                  Ինքնաշխատ կձեւավորվի հանձնաժողով, որը կգնահատի Ձեր ֆունկցիոնալության գնահատման արդյունքում ընդունված
                  որոշումը
                </span>
              </div>
            </button>
          </div>
          <div className="menu">
            <div className="circle">
              <div className="circle-inline">
                <div className="title">e-Disability</div>
                <span style={{ padding: '0 2px' }}>Անձի ֆունկցիոնալության գնահատման համակարգ</span>
                <button className="play" onClick={() => setOpen(true)}>
                  <img src={play} alt="Դիտել տեղեկատու հոլովակը" />
                </button>
              </div>
              <img src={line} className="line" alt="" />
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="img">
            <a href="https://nork.am/" target="_blank" rel="noreferrer">
              <img src={NorkLogo} className="nork" alt="Նորք տեխնոլոգիաների կենտրոն" />
            </a>
            <p className="footer-text">© «Նորք» տեխնոլոգիաների կենտրոն {year}</p>
          </div>
          <div className="all-icons">
            {/* <div className="icons"><img src={hands} alt=""/></div>
                        <div className="icons"><img src={disability} alt=""/></div>
                        <div className="icons"><img src={ear} alt=""/></div>
                        <div className="icons"><img src={communication} alt=""/></div> */}
            <Tooltip
              placement="topRight"
              title={'Ներառականության աուդիոուղեցույց'}
              visible={true}
              overlayClassName="pulse-tooltip"
            >
              <button className="icons" onClick={handlePlayAudio}>
                <img src={eyes} alt="Ներառականության աուդիոուղեցույց" />
              </button>
            </Tooltip>
            {showAudio && (
              <audio controls autoPlay ref={audioRef}>
                <source src={AudioFile} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}
            {/* <div className="icons"><img src={head} alt=""/></div> */}
          </div>
        </footer>
      </div>
      <React.Fragment>
        <ModalVideo channel="custom" isOpen={isOpen} url={Video} onClose={() => setOpen(false)} />
      </React.Fragment>
    </>
  );
};

export default HomePage;
